import React from "react";
import styled from "styled-components";

const Video = (props) => {
    return (
            <video {...props}>Your browser does not support</video>
    );
};


export default Video;
