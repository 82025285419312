import React from "react";
import emailjs from "@emailjs/browser";
import { useState } from "react";
import styled from "styled-components";
import Loader from "../common-component/Loader";
import ErrorMessage from "../common-component/Error";

function Newsletter() {
    const MailState = Object.freeze({
        SENT: "sent",
        NOT_SENT: "not_sent",
        PROCESSING: "processing",
    });

    const [mailSend, setMailSend] = useState(MailState.NOT_SENT);
    const [error, setError] = useState(false);
    const [formValue, setFormValues] = useState({
        name: "",
        phone: "",
        city: "",
    });

    function handleChange(e) {
        const { name, value } = e.target;
        setFormValues({
            ...formValue,
            [name]: value,
        });
    }

    function handleSubmit(e) {
        e.preventDefault();
        setMailSend(MailState.PROCESSING);
        emailjs
            .send("service_kgkl43o", "template_p4y2a7i", formValue)
            .then((_) => setMailSend(MailState.SENT))
            .catch((err) => setError(err.message))
            .finally(() => setTimeout(() => setMailSend(MailState.NOT_SENT), 5000));
    }

    return (
        <div className="newsletter-area pb-110">
            <div className="container">
                <div className="newsletter-wrap">
                    <div className="newsletter-shape">
                        <img src="/img/other/cows-icons/cow2.png" alt="" />
                    </div>
                    <div className="newsletter-shape shape-two">
                        <img src="/img/other/cows-icons/cow3.png" alt="" />
                    </div>

                    <div className="newsletter-content">
                        <h2 className="title">Get a call back</h2>
                    </div>
                    <div className="newsletter-form">
                        <form onSubmit={handleSubmit}>
                            <input required minLength={3} name="name" onChange={handleChange} type="name" placeholder="Enter Your Name..." />
                            <input required minLength={3} name="city" onChange={handleChange} type="text" placeholder="Enter Your City..." />
                            <input required pattern="[6-9][0-9]{9}" name="phone" onChange={handleChange} type="text" placeholder="Enter Your Phone..." />
                            <button disabled={mailSend === MailState.PROCESSING} type="submit" className="btn">
                                {mailSend === MailState.PROCESSING ? (
                                    <>
                                        <Loader size={30} /> &ensp;
                                        <span>Processing</span>
                                    </>
                                ) : (
                                    "Submit"
                                )}
                            </button>
                        </form>
                        <Status> {mailSend === MailState.SENT && <p style={{ color: "green" }}>Your Will Received CallBack From Our Team!</p>} </Status>
                        {error && <ErrorMessage message={error.message}></ErrorMessage>}
                    </div>
                </div>
            </div>
        </div>
    );
}

const Status = styled.div`
    background-color: whitesmoke;
    padding-inline: 12px;
    font-size: 14px;
    border-radius: 4px;
    margin-top: 12px;
`;

export default Newsletter;
