import React from "react";
import useTranslationMod from "../hooks/useTranslationMod";
import styled from "styled-components";

const AboutPage = () => {
    const { t } = useTranslationMod("other");

    return (
        <Wrapper className="breeder-details-page mt-20">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h2 className=" text-center page-title">About </h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <p className="text-left text">{t("about.bpgs_was_founded")}</p>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    .text {
        line-height: 30px;
    }
    .second-language {
        margin-top: 30px;
    }
`;

export default AboutPage;
