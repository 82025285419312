import React from "react";
import useTranslationMod from "../hooks/useTranslationMod";
import styled from "styled-components";

function OurFacilities() {
    const { t } = useTranslationMod("facilites");

    return (
        <Wrapper className="my-4  container">
            <h2 className="my-4 text-center">OurFacilities</h2>
            <Card className="d-lg-flex flex-md-row-reverse align-items-center gap-5">
                <figure>
                    <img src={cardData[0].image} />
                    <figcaption> {cardData[0].description_eng} </figcaption>
                    <figcaption> {cardData[0].description_hi}</figcaption>
                </figure>
                <p className="text-left">
                    <span>{t("bpsg_built_over")}</span>
                </p>
            </Card>
            <br className="" />
            <h5> {t("importantly_house")} </h5>
            <br />
            <h5> {t("fresh_water")} </h5>
            <br />
            <p> {t("gaushala_has_large")} </p>
            <br />
            <div>
                <h5> {t("bpgs_bovine.title")} </h5>
                <ul>
                    <li> {t("bpgs_bovine.zone.a")} </li>
                    <li> {t("bpgs_bovine.zone.b")} </li>
                    <li> {t("bpgs_bovine.zone.c")} </li>
                    <li> {t("bpgs_bovine.zone.d")} </li>
                    <li> {t("bpgs_bovine.zone.e")} </li>
                </ul>
                <br />
                <h6 className="px-1"> {t("bpgs_bovine.note")} </h6>
            </div>
        </Wrapper>
    );
}

const Card = styled.div`
    gap: 10px;


    figure {
        min-width: 45%;
        img {
            width: 100%;
            border-radius: 10px;
            margin: auto;
        }

        figcaption {
            font-size: 12px;
            margin-top: 5px;
            font-style: italic;
        }
    }
`;

const Wrapper = styled.div`
    ul {
        list-style: none;
        padding: 0;
        margin-inline: 10px;
        border: 1px solid #ccc;
        border-radius: 10px;
        li {
            padding: 10px;
            /* padding: 0.5rem 0; */
            border-bottom: 1px solid #ccc;
            &:last-child {
                border-bottom: none;
            }
        }
    }
`;

export default OurFacilities;


const cardData = [
    {
        description_hi: "प्लास्टिक कचरा गायों के लिए अत्यधिक खतरनाक है; ये गायें (जो दिखाई जा रही हैं) को तुरंत बचाने की जरूरत है।",
        description_eng: "Plastic waste is extremely dangerous for cattle; these cows need immediate rescue",
        image: "/img/photo gallery/Rescue Treatment and Protection/F4.jpg"
    },
]