import React from "react";
import { Link } from "react-router-dom";
import  useTranslationMod  from "../hooks/useTranslationMod";


function AdoptionShop() {
    const { t } =  useTranslationMod();

    const DIARY_PRODUCTS = [
        {
            name: "Milk",
            image: "/img/other/milk.png",
        },
        {
            name: "Butter",
            image: "/img/other/butter.png",
        },
        {
            name: "Ghee",
            image: "/img/other/ghee.png",
        },
        {
            name: "Paneer",
            image: "/img/other/paneer.png",
        },
        {
            name: "Yogurt",
            image: "/img/other/yogurt.png",
        },
    ];
    return (
        <section className="adoption-shop-area">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-7 col-lg-9">
                        <div className="section-title text-center mb-65">
                            <div className="section-icon">
                                <img src="/img/icon/pawprint.png" alt="" />
                            </div>

                            <h2 className="title">{t("learn_dairy_products")}</h2>
                            <p>Dairy products keep muscles, bones, nerves, teeth, skin and vision healthy. For children it helps support growth, brain development and improve immunity.</p>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    {DIARY_PRODUCTS.map(({ name, image }) => {
                        return (
                            <div key={name} className="col-4 col-lg-2 col-md-2">
                                <div className="adoption-shop-item">
                                    <div className="adoption-shop-thumb">
                                        <img src={image} alt={name} />
                                    </div>
                                    <div className="adoption-shop-content">
                                        <h5 className="title text-sm">
                                            <Link to="/shop-details">{name}</Link>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
    );
}

export default AdoptionShop;
