import React from "react";
import styled from "styled-components";
import useTranslationMod from "../hooks/useTranslationMod";

const SpreadHappiness = () => {
    const { t } = useTranslationMod("other");
    const DATA = [
        {
            image: "img/photo gallery/Where Happeiness Spread/E1.jpg",
            image_caption: "Visitor celebrating Cow Hug Day, with full fervor मुलाक़ाती पूरे उत्साह के साथ काउ हग डे मना रहे हैं",
            description_en: "An annual function is celebrated on 6 Feb every year on the eve of Baba Phulle Sadh’s birthday. Gaubhakts (cow worshippers) from all 14 villages in the vicinity, and beyond, participate in the program.",
            description_hi: "हर साल 6 फरवरी को बाबा फुल्ले साध जी के जन्मदिवस के उपलक्ष्य में वार्षिक उत्सव मनाया जाता है। इस कार्यक्रम में आस-पास के 14 गांवों और दूर-दराज के गौभक्त (गाय के उपासक) भाग लेते हैं।",
        },
        {
            image: "img/photo gallery/Where Happeiness Spread/E2.jpg",
            image_caption: "Pious elderly lady celebrating Cow Hug Day काउ हग डे मनाती धर्मपरायण बुजुर्ग महिला",
            description_en: "We also celebrate Cow Hug Day on 14th Feb each year. Visitors are welcome to come to the Gaushala. Many people enjoy hugging and petting cows",
            description_hi: 'हम हर साल 14 फरवरी को "काउ हग डे" (गाय गले लगाओ दिवस) भी मनाते हैं। इस दिन आगंतुक गौशाला में आ सकते हैं। कई लोग गायों को गले लगाने और सहलाने का आनंद लेते हैं।',
        },
        {
            image: "img/photo gallery/Where Happeiness Spread/E3.jpg",
            image_caption: "Our Chairman feeding jaggery (gud) to a newborn हमारे सभापति जी नवजात को गुड़ खिलाते हुए",
            description_en: "In summer, we supply water to local schools, to any community functions or water the community plants as per requirement. Our NGO is always ready to provide food and milk to any needy person, from our kitchen. Medical aid is available on demand. Blood camps are also organized frequently.",
            description_hi: "गर्मियों के मौसम में, हम स्थानीय स्कूलों में पानी उपलब्ध कराते हैं, किसी भी सामुदायिक कार्यक्रम में पानी देते हैं, और सामुदायिक पौधों को आवश्यकता के अनुसार पानी दिया जाता है । हमारी एनजीओ हमेशा जरूरतमंदों को हमारे रसोईघर से भोजन और दूध उपलब्ध कराने के लिए तैयार रहती है। चिकित्सा सहायता भी मांग पर प्रदान की जाती है। इसके अलावा, रक्तदान कैंप भी नियमित रूप से आयोजित किए जाते हैं।",
        },
        {
            image: "img/photo gallery/Where Happeiness Spread/E7.jpg",
            image_caption: " Incredibly cute young infants (calves) बेहद प्यारे छोटे नवजात बछड़े।",
            description_en: "In the vicinity, there are about 14 villages, called Khap, all of whom are closely associated with our Gaushala. All villagers donate in some form … to the extent of their personal capacities. Some donate by sending cows and bulls that they no longer have use for",
            description_hi: 'गौशाला के आसपास लगभग 14 गांव हैं, जिन्हें "खाप" कहा जाता है। ये सभी गांव हमारी गौशाला से गहराई से जुड़े हुए हैं। सभी ग्रामीण अपनी व्यक्तिगत क्षमता के अनुसार किसी न किसी रूप में दान देते हैं। कुछ लोग ऐसी गायों और बैलों को दान में देते हैं, जिनका उनके पास अब कोई उपयोग नहीं है।',
        },
    ];

    return (
        <div className="breeder-details-page mt-20">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 mb-4">
                        <h2 className="page-title text-center">Social activities at BPSG</h2>
                    </div>
                </div>

                {DATA.map((d) => {
                    return (
                        <RowContainer className="row py-5">
                          <div className="image-box col-md-5">
                                <figure>
                                    <Image src={d.image} alt="Food Provision" />
                                    <figcaption>{d.image_caption}</figcaption>
                                </figure>
                            </div>
                            <div className="content-box col-md-7">
                                <div>
                                    <p> {d.description_en} </p>
                                    <p> {d.description_hi} </p>
                                </div>
                            </div>
                        </RowContainer>
                    );
                })}
            </div>
        </div>
    );
};

const RowContainer = styled.div`
    margin: 20px 0;

    @media screen and (min-width: 768px) {
        &:nth-child(even) .content-box {
            order: -1;
        }
    }

    .content-box {
        margin-top: 10px;
    }

    figcaption{
        font-size: 12px;
        margin-top: 5px;
        font-style: italic;
    }
`;

const Image = styled.img`
    max-width: 100%;

    height: auto;
    border-radius: 10px;
`;

export default SpreadHappiness;
