import React from "react";
import DATA from "./photo-gallery/photogallery.json";
import styled from "styled-components";
const EventUpdates = () => {
    return (
        <div className="breeder-details-page mt-20">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 mb-4">
                        <h2 className="page-title text-center">Event And Updates</h2>
                    </div>
                </div>

                <RowContainer className="row py-5">
                    {DATA.find((d) => d.id === "events-updates")["data"].map((data, index) => {
                        return (
                            <div key={index} className="image-box col-sm-6 col-md-4">
                                <figure>
                                    <Image src={data.image} alt="" />
                                    <figcaption className="image-caption text-sm">{data.caption}</figcaption>
                                </figure>
                            </div>
                        );
                    })}
                </RowContainer>
            </div>
        </div>
    );
};

const RowContainer = styled.div`
    margin: 20px 0;

    @media screen and (min-width: 768px) {
        &:nth-child(even) .content-box {
            order: -1;
        }
    }

    .image-box {
        margin-top: 10px;
    }

    figcaption{
        margin-top: 5px;
        font-size: 10px;
    }
`;

const Image = styled.img`
    max-width: 100%;

    height: auto;
    border-radius: 10px;
`;

export default EventUpdates;
