import React from "react";
import styled from "styled-components";
import useTranslationMod from "../hooks/useTranslationMod";

const MedicalTreatment = () => {
    const { t } = useTranslationMod("other");

    const DATA = [{ image: "img/other/medical treatment/cow_checkup1.jpg" }, { image: "img/other/medical treatment/cow_checkup2.jpg" }, { image: "img/other/medical treatment/cow_checkup3.jpg" }, { image: "img/other/medical treatment/cow_injured.jpg" }, { image: "img/other/medical treatment/cow_sitted.jpg" }];

    return (
        <div className="breeder-details-page mt-20">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 mb-4">
                        <h2 className="page-title text-center">Medical Treatment</h2>
                    </div>
                </div>
                <div>
                    <p>{t("medical_facilites.treatment_process")}</p>
                    <p>{t("medical_facilites.bovine_medical_clinic")}</p>
                    <p>{t("medical_facilites.quarantine_facility")}</p>
                </div>
                <ImageContainer className="row d-flex py-5">
                    {DATA.map(({ image }) => {
                        return <Image src={image} alt="Food Provision" />;
                    })}
                </ImageContainer>
            </div>
        </div>
    );
};

const ImageContainer = styled.div`
    display: flex;
    gap: 10px;
`;

const Image = styled.img`
    flex-grow: 1;
    min-width: 0px;
    max-width: 100%;
    flex-basis: 32%;
    height: auto;
    border-radius: 10px;
`;

export default MedicalTreatment;
