import { BrowserRouter as Router, Switch } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import "./App.css";
import Home from "./pages/Home";

import BlogPage from "./pages/BlogPage";
import BlogDetailsPage from "./pages/BlogDetailsPage";
import PhotoGallery from "./pages/photo-gallery/PhotoGallery";

import MessageFromChairman from "./pages/MessageFromChairman";
import MessageFromPresident from "./pages/MessageFromPresident";
import Production from "./pages/Production";
import OurFacilities from "./pages/OurFacilities";
import SpreadHappiness from "./pages/SpreadHappiness";
import MedicalTreatment from "./pages/MedicalTreatment";
import FoodProvision from "./pages/FoodProvision";
import RescueProcess from "./pages/RescueProcess";
import About from "./pages/About";
import CowGenetics from "./pages/CowGenetics";
import VideoVault from "./pages/VideoVault";
import Patrons from "./pages/Patron";
import Contact from "./pages/contact/Contact";
import ScrollToTopRoute from "./ScrollToTopRoute";
import EventUpdates from "./pages/EventUpdates";
import HGSAsupport from "./pages/HGSAsupport";

function App() {


    return (
        <div className="app">
            <Router>
                <Header />
                <Switch>
                    <ScrollToTopRoute exact path="/">
                        <Home />
                    </ScrollToTopRoute>
                    <ScrollToTopRoute exact path="/food_provision">
                        <FoodProvision />
                    </ScrollToTopRoute>
                    <ScrollToTopRoute exact path="/events-updates">
                        <EventUpdates />
                    </ScrollToTopRoute>
                    <ScrollToTopRoute exact path="/rescue_process">
                        <RescueProcess />
                    </ScrollToTopRoute>
                    <ScrollToTopRoute exact path="/medical-treatment">
                        <MedicalTreatment />
                    </ScrollToTopRoute>
                    <ScrollToTopRoute exact path="/spread-happiness">
                        <SpreadHappiness />
                    </ScrollToTopRoute>
                    <ScrollToTopRoute exact path="/patrons">
                        <Patrons />
                    </ScrollToTopRoute>
                    <ScrollToTopRoute exact path="/our_facilities">
                        <OurFacilities />
                    </ScrollToTopRoute> 
                    <ScrollToTopRoute exact path="/cow-genetics">
                        <CowGenetics />
                    </ScrollToTopRoute>
                    <ScrollToTopRoute exact path="/about">
                        <About />
                    </ScrollToTopRoute>
                    <ScrollToTopRoute exact path="/video-vault">
                        <VideoVault />
                    </ScrollToTopRoute>
                    <ScrollToTopRoute exact path="/contact">
                        <Contact />
                    </ScrollToTopRoute>
                    <ScrollToTopRoute exact path="/message-from-chairman">
                        <MessageFromChairman />
                    </ScrollToTopRoute>
                    <ScrollToTopRoute exact path="/message-from-president">
                        <MessageFromPresident />
                    </ScrollToTopRoute>
                    <ScrollToTopRoute exact path="/production">
                        <Production />
                    </ScrollToTopRoute> 
                    <ScrollToTopRoute exact path="/cattle-blog">
                        <BlogPage />
                    </ScrollToTopRoute>
                    <ScrollToTopRoute exact path="/cattle-blog/:blogId">
                        <BlogDetailsPage />
                    </ScrollToTopRoute>
                    <ScrollToTopRoute exact path="/photo-gallery">
                        <PhotoGallery />
                    </ScrollToTopRoute>
                    <ScrollToTopRoute exact path="/hgsa-support">
                        <HGSAsupport />
                    </ScrollToTopRoute>
                </Switch>
                <Footer />
            </Router>
        </div>
    );
}

export {Router}

export default App;
