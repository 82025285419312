import { Link } from "react-router-dom";

const Logo = ({ width = 150, height }) => {
    if (height) width = undefined;
    return (
        <div className="logo-container">
            <Link to="/">
                <img src="/img/logo/logo.png" alt="logo"  width={width} height={height} />
            </Link>
        </div>
    );
};

export default Logo;
