import React from "react";
// import { Component } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import useTranslationMod from "../hooks/useTranslationMod";

function PrevArrow(props) {
    const { className, onClick } = props;
    return (
        <button type="button" className={className} onClick={onClick}>
            <img src="/img/icon/arrow.png" alt="PREV" />
        </button>
    );
}

function BreedServices() {
    const {t} = useTranslationMod();
    const SliderImages = [
        { name: "Gir Cattle", image: "/img/cow-breeds/Gir-Cow.jpg" },
        { name: "Haryana Cattle", image: "/img/cow-breeds/Hariana-cattle.jpg" },
        { name: "Holstein", image: "/img/cow-breeds/Holstein-Friesian.jpg" },
        { name: "Kankrej", image: "/img/cow-breeds/Kankrej.jpg" },
        { name: "Kherigarh", image: "/img/cow-breeds/kherigarh.jpg" },
        { name: "Red Sindhi", image: "/img/cow-breeds/Red-Sindhi.jpg" },
        { name: "Sehiwal", image: "/img/cow-breeds/Sehiwal.jpg" },
        { name: "Tharparkar", image: "/img/cow-breeds/Tharparkar-cattle.jpg" },
    ];

    const settings = {
        nav: true,
        dots: false,
        infinite: true,
        speed: 1000,
        autoplay: true,
        arrows: true,
        prevArrow: <PrevArrow />,
        autoplaySpeed: 4000,
        slidesToShow: 2,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    speed: 1000,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    speed: 1000,
                },
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    speed: 1000,
                },
            },
        ],
    };
    return (
        <section className="breeds-services pt-110 pb-110">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-7 col-lg-9">
                        <div className="section-title text-center mb-65">
                            <div className="section-icon">
                                <img src="/img/icon/pawprint.png" alt="" />
                            </div>
                            <h5 className="sub-title">Upgrade Your knowledge</h5>
                            <h2 className="title">{t("common_breeds")}</h2>
                            <p>{t('desi_cattle')}</p>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <Slider className="breed-services-active slick" {...settings}>
                            
                            {SliderImages.map(({ name, image }) => {
                                return (
                                    <div key={name} className="breed-services-item">
                                        <div className="thumb">
                                            <img src={image} alt="" />
                                        </div>
                                        <div className="content">
                                            <h3 className="title">
                                                <Link to="/breeder-details">{name}</Link>
                                            </h3>
                                        </div>
                                    </div>
                                );
                            })}
                        </Slider>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="breed-services-info" style={{ background: 'var(--main-color)' }}>
                            <h5 className="sub-title">Upgrade Your knowledge</h5>
                            <h3 className="title">Common Indian Cow Breeds</h3>
                            <p>Learn about their origin, physical characteristics, milk composition, and adaptability</p>
                            <Link to="/cattle-blog/common-cow-breeds-at-bps-gaushala" className="btn">
                                Learn More <img src="/img/icon/w_pawprint.png" alt="" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="breed-services-shape">
                <img src="/img/images/breed_services_shape01.png" alt="" />
            </div>
            <div className="breed-services-shape shape-two">
                <img src="/img/images/breed_services_shape02.png" alt="" />
            </div>
        </section>
    );
}

export default BreedServices;
