import { useTranslation } from "react-i18next";
import React from "react";

const useTranslationMod = (namespace, secondLanguage = "hi") => {
    const { t  } = useTranslation(namespace);

    function tMod(name , options = {}) {
        console.log(options)
        const englishText = t(name , {...options});
        const hindiText = t(name, {...options, lng: secondLanguage });

        console.log(englishText)
        

        return (
            <>
                <span>{englishText}</span>
                <p className="second-language">{hindiText}</p>
            </>
        );
    }

    return { t: tMod };
};

export default useTranslationMod;
