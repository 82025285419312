import React from "react";
import styled from "styled-components";
import useTranslationMod from "../hooks/useTranslationMod";

const FoodProvision = () => {
    const { t } = useTranslationMod("other");


    const foodProvisionContent = [
        {
            image: "img/other/food page/dry_Food.jpg",
            description_en: "Arranging and managing enormous quantities of fodder is certainly a challenge, but at BPSG Gaushala we have developed an elaborate rural supply-chain to handle the same, involving the regional village community. Over 1000 cattle are fed and there is never a shortage due to our well-developed and time-tested process.",
            description_hi: "गायों के लिए बड़े पैमाने पर चारे की व्यवस्था और प्रबंधन करना एक चुनौतीपूर्ण कार्य है, लेकिन बीपीएसजी गौशाला में हमने इसे संभालने के लिए एक विस्तृत ग्रामीण आपूर्ति श्रृंखला विकसित की है, जिसमें स्थानीय ग्रामीण किसानों का सहयोग लिया जाता है। हमारी गौशाला में 1000 से अधिक गायों को चारा दिया जाता है, और सुविधाजनक और परखे हुए प्रक्रियाओं के कारण कभी चारे की कमी नहीं होती।",
        },
        {
            image: "img/other/food page/grass_food.jpg",
            description_en: "Dry Fodder (Turi) is collected once in a year. Many farmers around Kapro and Narnaund Block donate to the Gaushala. Usually our godowns (granaries) are filled to the brim with the inputs received from farmers, but if there is spare capacity we purchase ourselves.",
            description_hi: "सूखा चारा (तूरी) साल में एक बार इकट्ठा किया जाता है। कपड़ो और खाप के आसपास के कई किसान गौशाला में दान देते हैं। आमतौर पर, हमारे गोदाम (अनाज भंडार) किसानों से प्राप्त चारे से भरे रहते हैं। लेकिन अगर भंडारण की जगह बचती है, तो हम खुद चारा खरीदते हैं।",
        },
        {
            image: "img/other/food page/Water1.jpg",
            description_en: "The same applies to Green Fodder; many farmers donate some crop. Some of them even donate land to grow fodder, for our philanthropic purposes. In addition, BPGS also grows green fodder on its own Agricultural land, which stretches over 30 Acres, and some additional land has to be taken on lease from the local Gram Panchayat for the purpose of growing crop used as feed",
            description_hi: "हरा चारे के लिए भी यही प्रक्रिया है। कई किसान कुछ फसल दान करते हैं। कुछ किसान तो हमारे परोपकारी उद्देश्य के लिए अपनी जमीन भी दान कर देते हैं। इसके अलावा, बीपीएसजी अपनी 30 एकड़ कृषि भूमि पर खुद भी हरा चारा उगाता है। कभी-कभी चारा उगाने के लिए स्थानीय ग्राम पंचायत से अतिरिक्त जमीन लीज पर लेनी पड़ती है।",
        },
    ];


    return (
        <div className="breeder-details-page mt-20">
            <div className="container">
                <div className="row my-4">
                    <div className="col-md-12">
                        <h2 className="page-title text-center">Food Provision</h2>
                    </div>
                </div>
                {/* <p>{t("food_provision.arranging")} </p>
                <p>{t("food_provision.dry_fodder")} </p>
                <p>{t("food_provision.same_applies")} </p> */}
                {foodProvisionContent.map((data) => {
                    return (
                        <RowContainer className="row py-5">
                            <div className="image-box col-md-5">
                                <Image src={data.image} alt="Food Provision" />
                            </div>
                            <div className="content-box col-md-7">
                                <div>
                                    {/* <h3> {data.title} </h3> */}
                                    <p> {data.description_en} </p>
                                    <p> {data.description_hi} </p>
                                </div>
                            </div>
                        </RowContainer>
                    );
                })}
            </div>
        </div>
    );
};

const RowContainer = styled.div`
    margin: 20px 0;

    @media screen and (min-width: 768px) {
        &:nth-child(even) .content-box {
            order: -1;
        }
    }

    .content-box {
        margin-top: 10px;
    }
`;

const Image = styled.img`
    max-width: 100%;

    height: auto;
    border-radius: 10px;
`;



export default FoodProvision;
