import React, { useState, useRef } from "react";
import styled from "styled-components";
import { RescueForm, DonationForm } from "./Form";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

const Contact = () => {
    const [activeTab, setActiveTab] = useState(0);
    const activeTabElementRef = useRef();

    const SlideChange = (index) => {
        setActiveTab(index);
        activeTabElementRef.current.style.transform = "translateX(" + index * 100 + "%)";
    };

    const handleSubmit = (data, type) => {
        toast.promise(axios.post(`${type}`, data), {
            loading: `Submitting your ${type} form request...`,
            success: `Your ${type} form request has been submitted successfully`,
            error: (err) => `Failed to submit ${type} form request: ${JSON.stringify(err.response.data.message)}`,
        });
    };

    return (
        <Wrapper>
            <Toaster />
            <h2 className="my-5 text-center">Contact Us</h2>
            <div className="contact-page px-3">
                <p>
                    <i className="fas fa-phone" style={{ transform: "rotate(90deg)" }}></i> Phone: 9896784225
                </p>
                <p>
                    <i className="fas fa-envelope"></i> Email:
                    <a href="mailto:gaushalakapro@gmail.com">
                        &nbsp; <b>gaushalakapro@gmail.com</b>
                    </a>
                </p>
                <p>
                    <i className="fas fa-map-marker-alt"></i> Address: Kapro Village, Hisar District, Haryana
                </p>
            </div>
            <FormBlock>
                <div className="form-header">
                    <div onClick={() => SlideChange(0)} className={`tabs ${activeTab === 0 ? "active" : ""}`}>
                        <h5 className="m-0">Rescue</h5>
                    </div>
                    <div onClick={() => SlideChange(1)} className={`tabs ${activeTab === 1 ? "active" : ""}`}>
                        <h5 className="m-0">Donation</h5>
                    </div>
                    <div ref={activeTabElementRef} className="glider"></div>
                </div>
                <div className="form-body">{activeTab ? <DonationForm onSubmit={(data) => handleSubmit(data, "donation")} /> : <RescueForm onSubmit={(data) => handleSubmit(data, "rescue")} />}</div>
            </FormBlock>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    margin-bottom: 20px;

    .contact-page {
        width: fit-content;
        margin: auto;
        text-align: left;
    }

    i {
        color: var(--main-color);
        font-size: 20px;
        margin-right: 10px;
    }

    p {
        margin-bottom: 20px;
        width: auto;
    }

    a {
        color: #f65008;
    }

    a:hover {
        color: #f65008;
        text-decoration: none;
    }
`;

const FormBlock = styled.div`
    --totalItems: 2;
    padding: 5px;

    .form-header {
        display: flex;
        margin: auto;
        width: fit-content;
        border: 8px white solid;
        text-transform: capitalize;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        position: relative;
        z-index: 1;

        .glider {
            position: absolute;
            transition: transform 0.5s ease;
            content: "";
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: var(--main-color);
            z-index: -1;
            width: calc(100% / var(--totalItems));
        }

        .glider,
        & {
            border-radius: 7px;
        }

        .tabs {
            padding: 12px 50px;
            cursor: pointer;
            width: calc(100% / var(--totalItems));
        }

        .tabs h5 {
            transition: color 0.5s ease;
            color: inherit;
        }

        .tabs.active {
            color: white !important;
        }
    }

    .form-body {
        margin-top: 10px;
        width: max(55%, 650px);
        @media screen and (max-width: 700px) {
            width: 100%;
        }
        padding: 40px 30px;
        margin-inline: auto;
        border-radius: 10px;

        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    }
`;

export default Contact;
