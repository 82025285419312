import React from "react";
import Slider from "react-slick";
import useTranslationMod from "../hooks/useTranslationMod";

function Testimonial() {
    const { t } = useTranslationMod();
    const TESTIOMONIALS = [
        {
            name: "Ajit Singh",
            location: "Hisar",
            profile_img: "/img/testimonials/ajit.jpg",
            feedback: "It’s heartwarming to see so many selfless volunteers, dedicated to the cause of helping and preserving cattle. I was especially moved by the rescue process, and the nursing immediately after rescue. So often you see strays and homeless cows roaming the streets, eating things that are deadly. It’s nice to know someone cares enough to give them shelter, and more importantly—love.",
        },
        {
            name: "Ashish Saini",
            location: "Patna",
            profile_img: "/img/testimonials/ashish.jpg",
            feedback: "On the day of my outing to BPGS, I learned so much about the importance of the cow to Indian culture, and so many interesting facts about Dairy Products. Thoroughly enjoyed the visit. The ground staff were so caring, and the facilities are first rate.",
        },
        {
            name: "Prem Mehra",
            location: "Delhi",
            profile_img: "/img/testimonials/prem.jpg",
            feedback: "The cow has unparalleled significance in Indic religions. Lord Krishna was a cowherd, and the bull is depicted as the vehicle of Lord Shiva. Today the cow has almost become a symbol of Hinduism itself. I must laud BPGS for the great philanthropic work they are doing.",
        },
        {
            name: "Sunita Yadav",
            location: "Agra",
            profile_img: "/img/testimonials/sunita.jpg",
            feedback: "When you visit, you will note that managing Gaushala operations is no cake walk. Tons of fodder are required everyday, fresh water has to be sourced, long-drawn treatment procedures have to be executed and cow products have to be transported on time. I commend all the selfless volunteers of BPSG who have dedicated their lives in service of India’s most beloved animal—the Cow. You are true heroes.",
        },
    ];

    const settings = {
        dots: true,
        infinite: true,
        speed: 4000,
        autoplay: false,
        arrows: false,
        autoplaySpeed: 4000,
        slidesToShow: 2,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    speed: 1000,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    speed: 1000,
                },
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    speed: 1000,
                },
            },
        ],
    };
    return (
        <section id="testimonials" className="testimonial-area testimonial-bg">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-7 col-lg-9">
                        <div className="section-title text-center mb-65">
                            <div className="section-icon">
                                <img src="/img/icon/pawprint.png" alt="" />
                            </div>
                            <h5 className="sub-title">Testimonials</h5>
                            <h2 className="title">{t("happy_visitors")}</h2>
                            <p>{t('visitors_have')}</p>
                        </div>
                    </div>
                </div>
                <Slider className="row testimonial-active" {...settings}>
                    {TESTIOMONIALS.map((testimonial, index) => (
                        <div className="col" key={index}>
                            <div className="testimonial-item">
                                <div className="testi-avatar-thumb">
                                    <img src={testimonial.profile_img} alt={testimonial.name} />
                                </div>
                                <div className="testi-content">
                                    <p>“ {testimonial.feedback} ”</p>
                                    <div className="testi-avatar-info">
                                        <h5 className="title">{testimonial.name}</h5>
                                        <span>{testimonial.position}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </section>
    );
}

export default Testimonial;
