import React from "react";
import { Link } from "react-router-dom";

function BlogBreadcumb() {
    return (
        <section className="breadcrumb-area breadcrumb-bg" style={{ backgroundImage: 'url("/img/bg/breadcrumb_bg.jpg")' }}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="breadcrumb-content">
                            
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default BlogBreadcumb;
