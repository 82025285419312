import React, { useState } from "react";
import styled from "styled-components";

export const formHeaders = Object.freeze({
    RESCUE: "rescue",
    DONATION: "donation",
});

const RescueForm = ({ onSubmit }) => {
    const rescueFields = [
        { label: "Name", type: "text", name: "name", placeholder: "Enter your name", required: true },
        { label: "Phone ", type: "tel", name: "phone1", placeholder: "Enter primary phone number", required: true },
        { label: "Phone ", type: "tel", name: "phone2", placeholder: "Enter secondary phone number (optional)", required: false },
        { label: "Email", type: "email", name: "email", placeholder: "Enter email address (optional)", required: false },
        { label: "Rescue Location", type: "text", name: "rescueLocation", placeholder: "Enter rescue location", required: true },
        { label: "Rescue Details", type: "textarea", name: "rescueDetails", placeholder: "Enter details about the rescue", required: true },
    ];

    const [formData, setFormData] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(formData);
    };

    return (
        <Form name={formHeaders.RESCUE} onSubmit={handleSubmit}>
            {rescueFields.map((field) => (
                <div key={field.name} style={{ marginBottom: "1rem" }}>
                    <Label style={{ display: "block", marginBottom: "0.5rem" }}>{field.label}</Label>
                    {field.type === "textarea" ? <textarea rows={10} className="form-control" name={field.name} placeholder={field.placeholder} required={field.required} onChange={handleChange} style={{ width: "100%" }} /> : <input className="form-control" type={field.type} name={field.name} placeholder={field.placeholder} required={field.required} onChange={handleChange} style={{ width: "100%" }} />}
                </div>
            ))}
            <SubmitButton type="submit">Submit Rescue Form</SubmitButton>
        </Form>
    );
};

const DonationForm = ({ onSubmit }) => {
    const donationFields = [
        { label: "Name or Firm of Donor", type: "text", name: "name", placeholder: "Enter your name", required: true },
        { label: "Phone", type: "tel", name: "phone1", placeholder: "Enter primary phone number", required: true },
        { label: "Phone ", type: "tel", name: "phone2", placeholder: "Enter secondary phone number (optional)", required: false },
        { label: "Email", type: "email", name: "email", placeholder: "Enter email address (optional)", required: false },
        { label: "Donor’s City of Residence", name: "city", type: "text", placeholder: "Enter your city", required: true },
        {
            label: "How would you like to donate?",
            name: "donationType",
            type: "checkbox",
            required: true,
            options: [
                { label: "Funds/Money", value: "funds" },
                { label: "Food", value: "food" },
                { label: "Medicine", value: "medicine" },
                { label: "Equipment", value: "equipment" },
                { label: "Any Other", customValue: true, input: { name: "donationType_other" } },
            ],
        },
        { label: "Comment Box", name: "comments", type: "textarea", placeholder: "Enter any additional details", required: false },
    ];

    const [formData, setFormData] = useState({});

    const handleCustomCheckboxValues = (e) => {
        const { name, value } = e.target;
    };

    const handleChange = (e) => {
        const { name, type, value, checked } = e.target;

        setFormData((prev) => {
            if (type === "checkbox") {
                const currentValues = prev[name] || [];
                return {
                    ...prev,
                    [name]: checked
                        ? [...currentValues, value] // Add value if checked
                        : currentValues.filter((v) => v !== value), // Remove value if unchecked
                };
            }

            return {
                ...prev,
                [name]: value,
            };
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(formData)
    };

    return (
        <Form name={formHeaders.DONATION} onSubmit={handleSubmit}>
            {donationFields.map((field) => (
                <div key={field.name} style={{ marginBottom: "1rem" }}>
                    <Label style={{ display: "block", marginBottom: "0.5rem" }}>{field.label}</Label>
                    {field.type === "textarea" ? (
                        <textarea rows={10} name={field.name} placeholder={field.placeholder} required={field.required} onChange={handleChange} style={{ width: "100%", padding: "0.5rem" }} />
                    ) : field.type === "checkbox" ? (
                        field.options.map((option) => (
                            <div key={option.value}>
                                <Label>
                                    <input type="checkbox" name={field.name} value={option.value} onChange={handleChange} />
                                    {option.label}
                                </Label>
                                {option.customValue ? (
                                    <div>
                                        <input onChange={handleCustomCheckboxValues} type="text" name={field.name} placeholder={field.placeholder} style={{ width: "100%", padding: "0.5rem" }} />
                                    </div>
                                ) : null}
                            </div>
                        ))
                    ) : (
                        <input type={field.type} name={field.name} placeholder={field.placeholder} required={field.required} onChange={handleChange} style={{ width: "100%", padding: "0.5rem" }} />
                    )}
                </div>
            ))}
            <SubmitButton type="submit">Submit Donation Form</SubmitButton>
        </Form>
    );
};

const SubmitButton = styled.button`
    width: fit-content;

    justify-content: end;
    padding: 8px 20px;
    font-size: 16px;
    color: #fff;
    background-color: var(--main-color);
    border: none;
    border-radius: 4px;
    transition: opacity ease-out 0.2s;
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
`;

const Label = styled.label`
    display: block;
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: bold;
    color: var(--main-color);
`;

const Form = styled.form`
    input,
    textarea {
        box-shadow: none;
        width: 100%;
        border-radius: 5px;
        border: none;
        /* border: 2px solid gray; */
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    }
    input[type="checkbox"] {
        display: inline-flex;
        margin-right: 10px;
        cursor: pointer;
        box-shadow: none;
        width: fit-content !important;
    }
`;

export { DonationForm, RescueForm };
