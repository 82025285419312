import React from "react";
import HomeExtraInformation from "./HomeExtraInformation";
import useTranslationMod from "../hooks/useTranslationMod";
import { useGSAP } from "@gsap/react";
import { useRef } from "react";
import gsap from "gsap";

function Counter() {
    const container = useRef();
    const { t } = useTranslationMod();
    useGSAP(
        () => {
            const t1 = gsap.timeline({
                scrollTrigger: {
                    trigger: container.current,
                    start: "top 80%",
                    end: "bottom 20%",
                    // duration: 1.2,
                    ease: 'linear',
                    markers: false,
                    toggleActions: "restart none none none",
                },
            });

            t1.from(".box", {
                x: 60,
                opacity: 0,
                ease: "power4.out",
                stagger: 0.3,
            });
        },
        { scope: container }
    );
    return (
        <section className="pt-4" style={{ backgroundImage: "url('/img/bg/counter_bg.jpg')" }}>
            <HomeExtraInformation />
            <div ref={container} className="container mt-70">
                <div className="row justify-content-center">
                    <div className="col-xl-6 col-lg-8">
                        <div className="counter-title text-center mb-65">
                            <h6 className="sub-title">Why Choose Us?</h6>
                            <h2 className="title"> {t("dedication")}</h2>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="box col-lg-3 col-md-4 col-sm-6">
                        <div className="counter-item">
                            <h2 className="count">
                                <span className="odometer">1018</span>
                            </h2>
                            <p>
                                Cattle Residing <br /> (मवेशी निवास){" "}
                            </p>
                        </div>
                    </div>
                    <div className="box col-lg-3 col-md-4 col-sm-6">
                        <div className="counter-item">
                            <h2 className="count">
                                <span className="odometer">6915</span>
                            </h2>
                            <p>
                                Cattle Saved <br /> (मवेशी बचाये)
                            </p>
                        </div>
                    </div>
                    <div className="box col-lg-3 col-md-4 col-sm-6">
                        <div className="counter-item">
                            <h2 className="count">
                                <span className="odometer">36</span>
                            </h2>
                            <p>
                                New Innovations <br /> (नया आविष्कार)
                            </p>
                        </div>
                    </div>
                    <div className="box col-lg-3 col-md-4 col-sm-6">
                        <div className="counter-item">
                            <h2 className="count">
                                <span className="odometer">45</span>+
                            </h2>

                            <p>
                                Years <br /> (वर्ष){" "}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Counter;
