import React from "react";
import styled from "styled-components";
import DATA from "./photogallery.json";

const PhotoGallery = () => {
    return (
        <section className="mt-20">
            <h1 className="text-center title">Photo Gallery</h1>
            {DATA.map((currentElement, index) => {
                return (
                    <div key={index} className="container my-4">
                        <Title> {currentElement.category_name} </Title>
                        {currentElement.data.map((_, index) => {
                            return (
                                <Card key={index} className="mt-20">
                                    <div className="box1">
                                        <img className="rounded img-loading" src={_.image}></img>
                                    </div>
                                    <div className="box2 px-3 ">
                                        <h3 className="title">{_.caption}</h3>
                                        <span className="second-language">{_.hindiText}</span>
                                    </div>
                                </Card>
                            );
                        })}
                    </div>
                );
            })}
        </section>
    );
};

const Title = styled.h3`
    font-size: 2rem; /* Default size */

    @media (max-width: 576px) {
        font-size: 1.5rem; /* Smaller size for small devices */
    }
`;

const Card = styled.div`
    display: flex;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 15px;
    border-radius: 10px;

    @media screen and (max-width: 800px) {
        flex-direction: column;
    }

    .box1 {
        width: 100%;
        img {
            width: 100%;
        }
    }

    .box2 {
        font-size: 27px;
        flex-basis: 40%;
        font-family: "Nunito";
        font-weight: 900;
      
        flex-basis: 40%;
        padding: 10px 0;

        @media (max-width: 1100px) {
            font-size: 18px;
        }
    }

    .title {
        color: var(--main-color);
    }
`;

export default PhotoGallery;
