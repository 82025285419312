import React from "react";
import styled from "styled-components";
import useTranslationMod from "../hooks/useTranslationMod";

const CowGenetics = () => {
    const { t } = useTranslationMod("other");
    const DATA = [
        {
            image: "img/other/cow-genetics/A6.jpg",
            image_caption: "Volunteers at a newly created outbuilding within the precincts of BPSG बीपीएसजी परिसर में बने नए भवन में स्वयंसेवक",
            description_en: "Science has shown that the natural reproduction process does not result in optimum breeding of cows, which are healthy, disease resistant and produce high quantities of milk. We therefore invest out time and energy towards optimum Bovine Breed development. This entails a number of processes (steps), but the area in which our NGO excels is “Sex Sorted Semen” of Verified Bulls or LUVAS. BPGS has first rate facilities for bovine semen management. This includes semen storage, retention and insemination facilities. We are a pioneer in this area in North India",
            description_hi: 'वैज्ञानिक शोधों से यह सिद्ध हुआ है कि प्राकृतिक प्रजनन प्रक्रिया से गायों की ऐसी प्रजाति तैयार नहीं हो पाती जो स्वस्थ, बीमारियों से प्रतिरोधक और अधिक मात्रा में दूध देने वाली हो। इसलिए, हम अपनी ऊर्जा और समय का निवेश गौवंश प्रजाति सुधार की दिशा में करते हैं। यह प्रक्रिया कई चरणों में पूरी होती है, लेकिन जिस क्षेत्र में हमारी गौशाला विशेष रूप से कुशल है, वह है "सेक्स सॉर्टेड सीमेन" (सत्यापित सांडों या लुवास का)। बीपीजीएस में गौवंश सीमेन प्रबंधन के लिए उच्च स्तरीय सुविधाएं उपलब्ध हैं। इनमें सीमेन भंडारण, संरक्षण और कृत्रिम गर्भाधान (Artificial Insemination) की सुविधाएं शामिल हैं। हम उत्तर भारत में इस क्षेत्र में श्रेष्ठ हैं।',
        },
        {
            image: "img/other/cow-genetics/B0.jpg",
            image_caption: "Newborn calf as a result of selective breeding. BPSG has a high success rate of IV based pregnancies and experts study Cow Genetics very deeply.चयनात्मक प्रजनन से जन्मा नवजात बछड़ा। बीपीएसजी में आईवी आधारित गर्भधारण की सफलता दर बहुत ऊंची है, और यहां के विशेषज्ञ गायों की जनन विज्ञान पर गहराई से अध्ययन करते हैं।",
            description_en: "Currently, the only way to improve “Bovine breed quality” in India is Sex Sorted Semen AI. Embro Transfer Technology is under trial in India, and may succeed in future; but has not been successful so far.",
            description_hi: 'भारत में वर्तमान समय में "गौवंश प्रजाति गुणवत्ता" सुधारने का एकमात्र तरीका सेक्स सॉर्टेड सीमेन एआई है। एम्ब्रियो ट्रांसफर तकनीक (Embryo Transfer Technology) अभी परीक्षण के दौर में है और भविष्य में सफल हो सकती है, लेकिन अब तक यह सफल नहीं हो पाई है।',
        },
    ];

    return (
        <div className="breeder-details-page mt-20">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="page-title text-center">Cow Genetics</h2>
                    </div>
                </div>

                {DATA.map((d) => {
                    return (
                        <>
                            <RowContainer className="row py-5 align-items-center">
                                <div className="image-box col-md-5">
                                    <figure>
                                        <Image src={d.image} alt="Food Provision" />
                                        <figcaption>{d.image_caption}</figcaption>
                                    </figure>
                                </div>
                                <div className="content-box col-md-7">
                                    <div>
                                        <p> {d.description_en} </p>
                                        <p> {d.description_hi} </p>
                                    </div>
                                </div>
                            </RowContainer>
                        </>
                    );
                })}
            </div>
        </div>
    );
};

const RowContainer = styled.div`
    margin: 20px 0;

    @media screen and (min-width: 768px) {
        &:nth-child(even) .content-box {
            order: -1;
        }
    }

    .content-box {
        margin-top: 10px;
    }
    figcaption{
        font-size: 12px;
        margin-top: 5px;
        font-style: italic;
    }
`;

const Image = styled.img`
    max-width: 100%;

    height: auto;
    border-radius: 10px;
`;

export default CowGenetics;
