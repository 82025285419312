import React from "react";
import styled from "styled-components";

function MessageFromChairman() {
    return (
        <section className="py-5">
            <VideoWrapper className="video-wrapper">
                <React.Fragment className="box">
                    <h3 className="mt-4">Message from Our Chairman</h3>
                    <VideoContainer className="video-container">
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/FeMMsUeUVds?autoplay=1&enablejsapi=1&mute=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                    </VideoContainer>
                
                </React.Fragment>
            </VideoWrapper>
        </section>
    );
}

const VideoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
`;

const VideoContainer = styled.div`
    width: 70%;
    aspect-ratio: 16/9;
    overflow: hidden;
    border-radius: 15px;
    background: linear-gradient(90deg, #e0e0e0 25%, #f4f4f4 50%, #e0e0e0 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite;

    @media screen and (max-width: 700px) {
        width: 90%;
    }

    iframe {
        width: 100%;
        border-radius: 15px;
        height: 100%;
        object-fit: cover;
    }
`;

export default MessageFromChairman;
