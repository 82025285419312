import React from "react";
import styled from "styled-components";
import useTranslationMod from "../hooks/useTranslationMod";
import Video from "../common-component/Video";
import { Swiper, SwiperSlide } from "swiper/react";

const RescueProcess = () => {
    const data = [
        {
            image: "img/other/rescue-process/cow.jpg",
            image_caption: "Plastic waste is extremely dangerous for cattle; these cows (shown) need immediate rescueप्लास्टिक कचरा गायों के लिए अत्यधिक खतरनाक है; ये गायें (जो दिखाई जा रही हैं) को तुरंत बचाने की जरूरत है।",
            description_en: "BPGS has a fast and effective rescue process. As soon when we receive information about an incident (or case) our volunteers spring into action immediately. Depending on the exact circumstances and situation, and severity of the stress, our selfless volunteers ensure a rescue within half an hour.",
            description_hi: "बीपीएसजी में एक तेज और प्रभावी रेस्क्यू प्रक्रिया है। जैसे ही किसी घटना या मामले की जानकारी मिलती है, हमारे स्वयंसेवक तुरंत हरकत में आ जाते हैं। स्थिति और तनाव की गंभीरता के अनुसार, हमारे निःस्वार्थ स्वयंसेवक यह सुनिश्चित करते हैं कि आधे घंटे के भीतर बचाव कार्य पूरा हो जाए।",
        },
        {
            image: "img/other/rescue-process/cow2.jpg",
            image_caption: " ",
            description_en: "Despite the fact that the cow is considered sacred in India, non-milking cows are considered a burden and they usually become strays. Farmers and residents who do not wish to upkeep non-milking cows send them to BPGS, and we have received approximately 200 such cattle every year",
            description_hi: "हालांकि भारत में गाय को पवित्र माना जाता है, लेकिन गैर-दूध देने वाली गायों को बोझ समझा जाता है और वे अक्सर बेसहारा बन जाती हैं। किसान और निवासी जो गैर-दूध देने वाली गायों की देखभाल नहीं करना चाहते, उन्हें बीपीएसजी भेज देते हैं। हर साल हमारी गौशाला में ऐसी लगभग 200 गायें आती हैं।",
        },
        {
            image: "img/other/rescue-process/group.jpg",
            image_caption: " Large group of volunteers, ready to serve ,स्वयंसेवकों का बड़ा समूह सेवा के लिए तैयार।",
            description_en: "Dedicated for the rescue process we have six tractors, multiple trollies and a manual cow lifting system (machine).  Currently we don't have proper ambulances.  Our team rescues almost 100 cattle annually, most of whom are found in highly critical conditions, some victims of near-fatal accidents. Many rescues cattle are severely injured or maimed. Many are victims of road accidents, simply because they have been abandoned by farmers or residents and have effective been rendered homeless.",
            description_hi: "रेस्क्यू के लिए समर्पित हमारी टीम के पास छह ट्रैक्टर, कई ट्रॉलियां और एक मैनुअल गाय उठाने की मशीन है। वर्तमान में हमारे पास उचित एम्बुलेंस नहीं है। हमारी टीम हर साल लगभग 100 गायों का रेस्क्यू करती है, जिनमें से अधिकांश अत्यधिक गंभीर स्थिति में पाई जाती हैं। इनमें से कई गायें लगभग घातक दुर्घटनाओं की शिकार होती हैं। कई गायें गंभीर रूप से घायल या विकलांग होती हैं। इनमें से अधिकांश सड़क दुर्घटनाओं की शिकार होती हैं क्योंकि उन्हें किसानों या निवासियों द्वारा छोड़ दिया जाता है, जिससे वे बेघर हो जाती हैं।",
        },
        {
            image: "img/other/rescue-process/group.jpg",
            image_caption: "Large group of volunteers, ready to serve ,स्वयंसेवकों का बड़ा समूह सेवा के लिए तैयार।",
            description_en: "A lot of water is required at BPSG Gaushala. Despite there being a shortage of water in the surrounding area, and depleting levels of fresh water in general in the country, we at BPGS manage to fill our water reservoirs from the Bhakra Canal situated 6 kilometers away. To facilitate the carriage of water, we have 3 different tanker vehicles who make multiple round trips in a day.",
            description_hi: "बीपीएसजी गौशाला में बड़ी मात्रा में पानी की आवश्यकता होती है। हालांकि आसपास के क्षेत्र में पानी की कमी है और पूरे देश में ताजे पानी के स्तर में गिरावट आ रही है, फिर भी हम भाखड़ा नहर, जो गौशाला से 6 किलोमीटर दूर है, उससे अपने जल भंडार भरने में सक्षम हैं। पानी लाने की सुविधा के लिए हमारे पास 3 टैंकर वाहन हैं, जो दिन में कई बार आना-जाना करते हैं।",
        },
    ];

    return (
        <div className="breeder-details-page mt-20">
            <div className="container">
                <div className="row my-4">
                    <div className="col-md-12">
                        <h2 className="page-title text-center ">Need for Rescue & Rescue Process</h2>
                    </div>
                </div>
                <Video className="mx-auto d-block rounded w-100 my-5" controls src="/video/rescue/video1.mp4"></Video>

                <Swiper spaceBetween={50} slidesPerView={1} className="py-2" breakpoints={{ 768: { slidesPerView: 2 } }}>
                    <SwiperSlide>
                        <div className="text-center">
                            <Video controls className="w-100 rounded" src="/video/rescue/leg.mp4"></Video>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="text-center">
                            <Video controls className="w-100 rounded" src="/video/rescue/leg2.mp4"></Video>
                        </div>
                    </SwiperSlide>
                </Swiper>
                {data.map((d) => {
                    return (
                        <RowContainer className="row py-5">
                            <div className="image-box col-md-5">
                                <figure>
                                    <Image src={d.image} alt="Food Provision" />
                                    <figcaption>{d.image_caption}</figcaption>
                                </figure>
                            </div>
                            <div className="content-box col-md-7">
                                <div>
                                    <p> {d.description_en} </p>
                                    <p> {d.description_hi} </p>
                                </div>
                            </div>
                        </RowContainer>
                    );
                })}
            </div>
        </div>
    );
};

const RowContainer = styled.div`
    margin: 20px 0;

    @media screen and (min-width: 768px) {
        &:nth-child(even) .content-box {
            order: -1;
        }
    }

    .content-box {
        margin-top: 10px;
    }

    figcaption{
        font-size: 12px;
        margin-top: 5px;
        font-style: italic;
    }
`;

const Image = styled.img`
    max-width: 100%;

    height: auto;
    border-radius: 10px;
`;
export default RescueProcess;
