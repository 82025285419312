import React from "react";
import useTranslationMod from "../hooks/useTranslationMod";
import styled from "styled-components";



function Production() {

    const productionContent = [
        {
            image: "img/other/food page/dry_Food.jpg",
            description_en: "About 200 Liters of milk is produced at BPGS every day. Being an NGO, we distribute a large component to employees, and a share is sold to poor villagers at cost that is below production (₹50 per kg). We also use some milk to produce healthy ghee, which is sold to needy people at the economical rate of ₹ 1500 per kg",
            description_hi: "बीपीएसजी में प्रतिदिन लगभग 200 लीटर दूध का उत्पादन होता है। एनजीओ होने के नाते, हम इसका बड़ा हिस्सा अपने कर्मचारियों को वितरित करते हैं और एक हिस्सा गरीब ग्रामीणों को उत्पादन लागत से कम दाम पर (₹50 प्रति किलो) बेचते हैं। इसके अलावा, कुछ दूध का उपयोग करके हम शुद्ध और स्वास्थ्यवर्धक घी बनाते हैं, जिसे जरूरतमंद लोगों को मात्र ₹1500 प्रति किलो की किफायती दर पर बेचा जाता है।",
        },
        {
            image: "img/other/food page/grass_food.jpg",
            description_en: "BPGS also produced manure from dung which can be used as organic fertilizer in agriculture. Manure is sold at highly economical rates to farmers, usually from nearby villages. In future we are planning to produce cow urine for use in organic farming and other purposes.",
            description_hi: "बीपीएसजी गोबर से खाद भी बनाता है, जिसे जैविक खेती के लिए उर्वरक के रूप में इस्तेमाल किया जा सकता है। यह खाद किसानों को, विशेष रूप से आसपास के गांवों के किसानों को, बहुत ही सस्ते दाम पर बेची जाती है। भविष्य में हम गोमूत्र का उत्पादन करने की योजना बना रहे हैं, जिसे जैविक खेती और अन्य उद्देश्यों के लिए इस्तेमाल किया जा सके।",
        },

    ];

    const { t } = useTranslationMod("other");
    return (
        <section>
            <div className="container">
                <div className="row ">
                    <div className="col-md-12 my-4">
                        <h2 className="page-title text-center">Prodcution</h2>
                    </div>
                </div>
                {productionContent.map((d) => {
                    return (
                        <RowContainer className="row py-5">
                            <div className="image-box col-md-5">
                                <figure>
                                    <Image src={d.image} alt="Food Provision" />
                                    <figcaption>{d.image_caption}</figcaption>
                                </figure>
                            </div>
                            <div className="content-box col-md-7">
                                <div>
                                    <p> {d.description_en} </p>
                                    <p> {d.description_hi} </p>
                                </div>
                            </div>
                        </RowContainer>
                    );
                })}
            </div>
        </section>
    );
}

const RowContainer = styled.div`
    margin: 20px 0;

    @media screen and (min-width: 768px) {
        &:nth-child(even) .content-box {
            order: -1;
        }
    }

    .content-box {
        margin-top: 10px;
    }

    figcaption{
        font-size: 12px;
        margin-top: 5px;
        font-style: italic;
    }
`;

const Image = styled.img`
    max-width: 100%;

    height: auto;
    border-radius: 10px;
`;
export default Production;
